@import "./Assets/Css/utilities.css";
@import "./Assets/Css/animations.css";

:root {
  --primaryColor: #387af6;
  --primaryColor2: #fbe209;
}
.fieldError {
  color: red;
}
.fieldError + .fieldError {
  display: none;
}
.small_logo {
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.circle {
  border-radius: 50%;
}
.mt-10em {
  margin-top: 10em !important;
}

.max-200 {
  max-width: 200px;
}
.ml-left{
  margin-left: 30px;

}