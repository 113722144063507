.dashboard-count-user {
  margin-bottom: 43px;

  @include onSmallScreen {
    margin-left: -10px;
    margin-right: -10px;
    div[class*="col"] {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .card {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    background-color: $white-color;
    border-radius: 10px;
    border: none;
    height: 100%;
  }
  .card-icon {
    font-size: 4.5rem;
    margin: 12.2px auto;
    object-fit: contain;
    height: 1em;
    width: 1em;
    @include onMediumScreen {
      font-size: 2.8125rem;
    }
    @include onSmallScreen {
      font-size: 2.1875rem;
    }
    @include onMobileScreen {
      margin: 10px auto;
    }
  }
  .view-all-link {
    text-align: right;
    display: block;
    font-family: $base-font1;
    font-size: 0.75rem;
    font-weight: 300;
    color: $color2;
    @include onMobileScreen {
      text-align: center;
    }
  }
  .card-title {
    font-family: $base-font1;
    font-size: 2.25rem;
    font-weight: bold;
    line-height: 1.53;
    color: $color1;
    @include onMediumScreen {
      font-size: 1.875rem;
    }
    @include onSmallScreen {
      font-size: 1.5rem;
    }
  }
  .card-text {
    font-family: $base-font1;
    font-size: 1.125rem;
    line-height: 1.22;
    text-align: center;
    color: $color1;
    @include onSmallScreen {
      font-size: 0.875rem;
    }
    @include onMobileScreen {
      font-size: 0.75rem;
    }
  }
  .btn {
    @include onMediumScreen {
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }
  }
}
.c-dashboard-team-member {
  padding-top: 20px;
  display: flex;
  align-items: center;
  &__img-wrapper {
    padding-right: 15px;
  }
  &__name {
    text-transform: capitalize;
    @include setMainHeading;
    color: $white-color;
  }
  &__image {
    &.image {
      @include setThumbinail(90px);
    }
  }
}
