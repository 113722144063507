.scrollMenu {
  height: calc(100vh - 114px);
  overflow: auto;
}

.copyright {
  position: absolute;
  bottom: 5px;
  display: flex;
  align-items: center;
  /* height: 53px; */
}

.copyright-image-sidebar {
  /* position: absolute;
  left: 5px;
  top: 18px; */
}

.sidebar-item .content1 {
  margin-bottom: 20px;
}

/* .copyright-text-sidebar {
  position: absolute;
  left: 50px;
  top: 20px;
} */
.content1 {
  /* max-height: 120px; */
  transition: max-height 5s ease-out;
}

.sidebar-item li {
  margin-bottom: 5px;
}

.selcted-active {
  background-color: #e7e7f7;
  border-radius: 6px;
}