@use "sass:math";
// videot therapy
.video-therapy {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;

  &__meeting-col {
    padding-left: 20px;
    padding-right: 20px;
    width: percentage($number: math.div(1375, 1920));
  }

  &__info {
    width: percentage($number: math.div(545, 1920));
    padding-left: 10px;
    padding-right: 40px;

    @include onMediumScreen {
      padding-right: 20px;
    }

    &-container {
      background: #fff;
      padding: 33px;
      border-radius: 15px;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);

      @include onMediumScreen {
        padding: 13px;
      }
    }

    &-time-col {
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 25px;
      flex: auto;
    }

    &-timer {
      border-radius: 5px;
      padding: 14px 24.5px 11px 25px;
      background-color: $light-blue;

      @include onMediumScreen {
        padding: 7px 12px 6px 12px;
      }

      h4 {
        font-size: 1.125rem;

        @include onMediumScreen {
          font-size: 0.875rem;
        }
      }

      h5 {
        font-size: 1rem;

        @include onMediumScreen {
          font-size: 0.8125rem;
        }
      }
    }
  }

  &__case-note {
    padding: 20px 24px;
    border-radius: 16px;
    border: solid 1px #bdcbd6;
    background-color: $white-color;

    @include onMediumScreen {
      padding: 10px 12px;
    }

    &-input {
      width: 100%;
      resize: none;
      border: none;
      font-family: $base-font1;
      font-size: 0.9375rem;
      line-height: 1.33;
      letter-spacing: 0.3px;
      text-align: left;
      color: #373737;
      min-height: 280px;

      &:focus {
        outline: none;
      }

      @include onMediumScreen {
        min-height: 180px;
      }
    }

    &-attachments {
      padding-bottom: 20px;
      display: flex;
      flex-wrap: wrap;
    }

    &-attachment {
      .btn {
        position: absolute;
        right: -2px;
        top: -6px;
        width: 24px;
        height: 24px;
        border-radius: 24px;
        padding: 0;
        z-index: 2;

        svg {
          display: block;
          width: 100%;
          height: auto;
        }
      }

      .attachment-img {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        border-radius: 10px;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        margin: auto;
        z-index: 0;
        object-fit: cover;
      }
    }
  }

  .paperclip-btn {
    margin-top: 10px;
    margin-bottom: 10px !important;

    @include onMediumScreen {
      margin-bottom: auto !important;
    }
  }
}

//agora video call
.avc {
  padding-left: 20px;
  padding-right: 20px;
  position: relative;

  @include onFullscreen {
    padding: 20px;
    background-color: $body-bg;
  }

  &__video {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 16px;
    overflow: hidden;
    background-color: rgba($black-color, 0.1);

    &-container {
      display: flex;
      flex-wrap: wrap;
      margin-left: -20px;
      margin-right: -20px;
      position: relative;

      &--has-pinned {
        .avc__video-wrapper {
          width: 100px;
          order: 1;

          @include onFullscreen {
            position: absolute;
            z-index: -1;
          }

          &--small {
            position: relative;
            bottom: auto;
            top: auto;
            left: auto;

            .avc__video {
              border-radius: 16px;
            }
          }

          &--pinned {
            width: 100% !important;
            margin-bottom: 20px;
            order: 0;

            @include onFullscreen {
              position: relative;
              z-index: 0;
            }

            &:before {
              padding-top: calc(66% - 20px);
            }
          }
        }
      }
    }

    &-pin-wrapper {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100px;
      height: 100px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      cursor: pointer;
      opacity: 0;
      transition: all 0.2s;
      border-radius: 100%;
      background-color: rgba($white-color, 0.2);

      svg {
        width: 60px;
        height: auto;
      }
    }

    &-inner {
      width: 100%;
      height: 100%;
    }

    &-indicators {
      position: absolute;
      z-index: 9;
      bottom: 0;
      right: 0;
      padding: 10px;
      display: flex;

      img {
        margin: 5px;
        width: 20px;
      }
    }

    &-wrapper {
      position: relative;
      width: calc(50% - 40px);
      margin-left: 20px;
      margin-right: 20px;
      z-index: 1;
      overflow: hidden;

      &::before {
        content: "";
        display: block;
        padding-top: 131.7%;

        @include onFullscreen {
          padding: 0;
          height: calc(100vh - 40px);
        }
      }

      &:hover {
        .avc__video-pin-wrapper {
          opacity: 1;
          z-index: 99999;
        }
      }

      &--small {
        position: absolute;
        bottom: 35px;
        left: 35px;
        z-index: 2;
        width: 256px;

        @include onMediumScreen {
          width: 150px;
          left: 15px;
          bottom: 15px;

          @include onFullscreen {
            bottom: 45px;
          }
        }

        &::before {
          padding-top: 100%;

          @include onFullscreen {
            width: 256px;
            height: 256px;

            @include onMediumScreen {
              width: 150px;
              height: 150px;
            }
          }
        }

        .avc__video {
          border-radius: 50%;

          &-indicators {
            left: 0;
            margin: auto;
            justify-content: center;
          }
        }
      }

      &--placeholder {
        .avc__video {
          background-color: $color4;
        }
      }

      &--black {
        .avc__video {
          background-color: $black-color;
        }
      }
    }
  }

  &__join-btn-wrapper {
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__info {
    width: 100%;
    padding-top: 33px;
    padding-bottom: 33px;
    display: flex;
    justify-content: center;

    // >div {
    //   flex: 1;
    // }

    &-rec-other {
      padding: 5px 10px 5px 26px;
      display: inline-block;
      border-radius: 5px;
      background-color: $light-blue;
      position: relative;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 10px;
        margin: auto;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background-color: $danger;
      }
    }

    @include onFullscreen {
      justify-content: center;
      padding: 20px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;
    }
  }

  @include onMediumScreen {
    &__stop-wrapper {
      max-width: 120px;

      @include onFullscreen {
        max-width: 100%;
      }
    }
  }

  &__users-wrapper {
    display: flex;
    justify-content: end;

    &[disabled] {
      opacity: 0.3;
      pointer-events: none;
    }
  }

  &__track-wrapper {
    display: flex;
    justify-content: center;

    .audio-video-background {
      margin: auto;
      width: 57px;
      height: 57px;
      padding: 0;
      align-items: center;
      justify-content: center;
      display: flex;
      position: relative;

      img,
      svg {
        max-width: 23px;
        // max-height: 16px;
      }

      svg {
        height: auto;
      }

      @include onMediumScreen {
        width: 40px;
        height: 40px;

        img,
        svg {
          max-width: 15px;
          max-height: 20px;
        }
      }

      &.muted {
        &:before {
          content: "";
          display: block;
          position: absolute;
          width: 40px;
          height: 5px;
          background: $light-blue;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          transform: rotate(-45deg);
          border-bottom: 3px solid $color2;
          z-index: 2;

          @include onMediumScreen {
            width: 24px;
            height: 3px;
            border-width: 1px;
          }
        }
      }

      &--screen {
        color: $color2;

        // color: $white-color;
        svg {
          fill: currentColor;
        }

        &.active {
          color: $white-color;
          background-color: $color2;

          &:before {
            display: none;
          }
        }
      }
    }
  }

  &__status {
    position: absolute;
    z-index: 3;
    padding: 10px;
    background: rgba($body-bg, 0.3);
    border-radius: 5px;
    top: 0;
    left: 20px;
  }
}

.streams--1 {
  width: 45%;
}

.streams--2 {
  width: 30% !important;
  height: 200px;
}

.streams--3 {
  width: 20% !important;
  // height: 200px;
}

.streams--4 {
  width: 12% !important;
  // height: 200px;
}

.streams--2 .over-img-middle .text,
.streams--3 .over-img-middle .text {
  color: white;
  font-size: 40px;
  background-color: #bf35d2;
  font-weight: bold;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  padding: 14px 10px 12px 10px;
  text-align: center;
  vertical-align: middle;
  line-height: 65px;
  text-transform: uppercase;
  width: 95px;
  // height: 95px;
}

.streams--2 .over-img-middle,
.streams--3 .over-img-middle {
  top: 30%;
}

.streams--2 .over-img-middle img,
.streams--3 .over-img-middle img {
  width: 100px;
  height: 100px;
}

.avc__video-container--has-pinned .avc__video-wrapper.streams--1 .over-img-middle img,
.avc__video-container--has-pinned .avc__video-wrapper.streams--2 .over-img-middle img,
.avc__video-container--has-pinned .avc__video-wrapper.streams--3 .over-img-middle img {
  color: white;
  text-align: center;
  vertical-align: middle;
  width: 100px !important;
  height: 100px !important;
}

.streams--4 .over-img-middle .text {
  color: white;
  font-size: 35px;
  background-color: #bf35d2;
  font-weight: bold;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  padding: 10px 5px 3px 5px;
  text-align: center;
  vertical-align: middle;
  line-height: 59px;
  text-transform: uppercase;
  width: 75px;
  // height: 95px;
}

.streams--4 .over-img-middle img {
  color: white;
  text-align: center;
  vertical-align: middle;
  width: 80px;
  height: 80px;
}

// .avc__video-container--has-pinned .avc__video-wrapper.streams--1 .over-img-middle img, .avc__video-container--has-pinned .avc__video-wrapper.streams--2 .over-img-middle img, .avc__video-container--has-pinned .avc__video-wrapper.streams--3 .over-img-middle img

.avc__video-container--has-pinned .avc__video-wrapper--pinned.streams--2 {
  height: unset;
}

.avc__video-container--has-pinned .avc__video-wrapper--pinned.streams--2 .over-img-middle .text {
  height: unset;
}

.avc__video-container--has-pinned .avc__video-wrapper--pinned.streams--1 {
  height: unset !important;
}

.avc__video-container--has-pinned .avc__video-wrapper.streams--1 {
  width: 30%;
  height: 200px;
}

.avc__video-wrapper.streams--2.avc__video-wrapper--black .over-img-middle .text {
  width: 95px !important;
  height: 95px;
}

.avc__video-container--has-pinned .avc__video-wrapper--pinned.streams--2 .over-img-middle img {
  color: white;
  text-align: center;
  vertical-align: middle;
  width: 200px !important;
  height: 200px !important;
}

.avc__video-container--has-pinned .streams--1 .over-img-middle .text,
.streams--0.avc__video-wrapper--pinned .over-img-middle .text,
.streams--1.avc__video-wrapper--pinned .over-img-middle .text,
.streams--2.avc__video-wrapper--pinned.avc__video-wrapper--pinned .over-img-middle .text,
.streams--3.avc__video-wrapper--pinned .over-img-middle .text,
.streams--3.avc__video-wrapper--pinned .over-img-middle .text {
  padding: 5px 14px 5px 18px !important;
  width: 100px !important;
  font-size: 50px !important;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avc__video-container--has-pinned .avc__video-wrapper--pinned.streams--2 .over-img-middle text {
  color: white;
  font-size: 45px;
  background-color: #bf35d2;
  font-weight: bold;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  padding: 10px;
  text-align: center;
  vertical-align: middle;
  line-height: 59px;
  text-transform: uppercase;
  width: 100px;
  // height: 100px;
}

// .avc__video-container--has-pinned .avc__video-wrapper {
//   width: unset;
// }
.avc__video-container--has-pinned .avc__video-wrapper.streams--2 {
  // width: 30%;
}

.fullscreen-enabled .avc__video-container--has-pinned .avc__video-wrapper--pinned {
  position: relative;
  z-index: 0;
  width: 100% !important;
  height: unset !important;
}

.fullscreen-enabled .streams--2 {
  height: unset;
}

.fullscreen-enabled .streams--3 {
  width: 30% !important;
  height: 330px;
}

.fullscreen-enabled .streams--3.total-user-6 {
  width: 22% !important;
  height: 280px;
}

.fullscreen-enabled .streams--3.total-user-7 {
  width: 22% !important;
  height: 200px;
}

.fullscreen-enabled .streams--4 {
  width: 18% !important;
  height: 200px;
}

.agora-full-screen {
  position: absolute;
  right: 20px;
  top: 0;
  z-index: 4;
}

.no-evt {
  pointer-events: none;
  opacity: 0.5;
}

.page-loader {
  pointer-events: none;

  &:after {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $white-color;
  }

  &:before {
    content: "Loading...";
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    // width: 40px;
    height: 40px;
    margin: auto;
    // border: 5px solid $color2;
    z-index: 999;
    // border-radius: 50%;
    // border-left-color: transparent;
    // border-right-color: transparent;
    // @extend
  }

  .permissions {}
}