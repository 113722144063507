.header {
  .user-info {
    margin-right: 1.5rem;
    .userName {
      color: #222248;
      margin-bottom: -5px;
    }
    .userEmail {
      color: #222248;
      //opacity: 0.5;
    }
  }
  .notificationBox {
    margin-right: 2rem;
    position: relative;
    cursor: pointer;
    .bellImage {
      width: 18px;
      margin-top: 6px;
    }
    .not-Count {
      position: absolute;
      width: 20px;
      height: 20px;
      background: #387af6;
      border-radius: 50%;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      top: -6px;
      right: -20px;
      font-size: 10px;
      left: 15px;
    }
  }
}
