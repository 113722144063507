// Input text height
.h-46 {
  height: 46px;
}

.button-withicon-green {
  background-image: linear-gradient(to bottom, $btn-green, #10c177 93%);
  border: none;
  font-family: $base-font1;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.36;
  text-align: left;
  color: $white-color;
}
.button-watchvideo-green {
  width: 130px;
  // background-image: linear-gradient(to bottom, $btn-green, #10c177 93%);
  background: #ffe73fe0;
  border: none;
  font-family: $base-font1;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.36;
  text-align: left;
  color: #000;
}
.therapy-session .ts-therapy-name {
  padding: 5px 10px 3px 10px;
  background-color: #e8f1ff;
  border-radius: 0px 4px 4px 0px;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  color: #222248;
  text-align: center;
  display: block;
}
.therapy-session .ts-therapy-name {
  font-size: 0.8125rem;
}
.therapy-session .ts-family-name {
  font-size: 0.75rem;
}
.ts-time {
  font-size: 0.75rem;
}
.c-therapy-session-table .btn {
  font-size: .75rem;
}
.c-therapy-session-table .btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.therapy-session .ts-session-duration, .therapy-session .ts-time-session {
  display: flex!important;
}
.custom-btn-green {
  border-radius: 6px;
  background-image: linear-gradient(to bottom, $btn-green, #10c177 93%);
  border: none;
  font-family: $base-font1;
  font-size: 1rem;
  color: $bg-color1;
  width: 153px;
  height: 43px;
  padding: 10px 23px 12px;
  background-color: $btn-green;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  img {
    width: 1.4em;
    height: 1.4em;
    object-fit: contain;
  }
  @include onMediumScreen {
    padding: 8px 15px 8px;
    height: auto;
    font-size: 0.875rem;
  }
  @include onSmallScreen {
    font-size: 0.875rem;
  }
  :not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    background-color: $btn-green;
    border-color: none;
  }
}

.for-ipad-green-btn {
  @include onMobileScreen {
    width: 84px;
    height: 50px;
    padding: 0px;
    margin-left: -13px;
  }
  @include onOnlyThisWidth(1024px) {
    width: 105px;
    height: 40px;
    padding: 0px;
    margin: 0px;
  }
}

.custom-btn-green-sm {
  width: 111px;
  height: 42px;
  @include onSmallScreen {
    width: 84px;
    height: 34px;
    padding: 0px;
  }
}

.custom-btn-green-auto {
  width: auto;
  height: auto;
  // padding: 9px 5px 7px 28px;
}

.stepper-button-next {
  border-radius: 10px;
  background-image: linear-gradient(to bottom, $btn-green, #10c177);
  width: 337.8px;
  height: 66px;
  border: none;
  font-family: $base-font1;
  font-size: 1.625rem;
  line-height: 1.31;
  text-align: center;
  color: $white-color;
  @include onSmallScreen {
    font-size: 1.375rem;
    width: 237.8px;
    height: 50px;
  }
}

.stepper-button-back {
  width: 337.8px;
  height: 66px;
  border-radius: 10px;
  border: solid 1px $color2;
  background-color: $white-color;
  font-family: $base-font1;
  font-size: 1.625rem;
  line-height: 1.31;
  text-align: center;
  color: $color2;
  margin-right: 47px;
  @include onSmallScreen {
    font-size: 1.375rem;
    width: 237.8px;
    height: 50px;
  }
}

.custom-btn-yellow {
  border-radius: 4px;
  background-image: linear-gradient(to bottom, $btn-yellow, #e5c01d);
  border: none;
  font-family: $base-font1;
  font-size: 0.875rem;
  font-weight: 300;
  color: $color6;
  width: 111px;
  height: 36px;
  padding: 4px 14px 5px 15px;
}

.custom-btn-yellow-sm {
  width: 80px;
  height: 28px;
  padding: 4px 14px 5px 15px;
  color: $color6;
}

// action status buttons
.therapy-session .session-status {
  width: 100%;
}
.session-status {
  // opacity: 0.78;
  border-radius: 6px;
  font-family: $base-font1;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.36;
  padding-left: 10px;
  padding-right: 10px;
  color: $white-color;
  display: inline-flex;
  align-items: center;

  img,
  svg {
    margin-right: 0.7em;
    width: 1.1em;
    height: auto;
    object-fit: contain;
    display: block;
  }
  &.s-complete {
    background-color: $s-complete;
  }
  &.s-approve {
    background-color: $s-approve;
    color: $color1;
  }
  &.s-pending {
    background-color: $s-pending;
    color: $color1;
  }
  &.s-missed {
    background-color: $s-missed;
    color: $color1;
  }
  &.s-cancelled {
    color: $white-color;
    background-color: $s-cancelled;
  }
}
.table-responsive {
  @include onMinWidth(1100px) {
    display: table;
  }
}
.date-width {
  @include onMobileScreen {
    width: 40px;
  }
}
.logo-img:hover path {
  fill: white;
}

.session-detail-section {
  padding: 20px;
  /* opacity: 0.1; */
  background-color: $bg-color2;
  border-radius: 16px;
  margin-top: 20px;
  @include onMediumScreen {
    padding: 15px;
  }
  .admin-name {
    justify-content: flex-start !important;
  }
  & > .row {
    margin-bottom: 10px;
  }
}

.session-detail-comp {
  hr {
    border-top: solid 1px $light-blue;
    background-color: $bg-color1;
  }
  .session-detail-date {
    font-family: $base-font1;
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.33;
    color: $color1;
    span {
      font-weight: 600;
    }
  }
}

.table td,
.table th {
  vertical-align: middle;
  padding: 10px;
}

// background color
.bg-color1 {
  background-color: $bg-color1 !important;
}

.bg-color2 {
  background-color: $bg-color2 !important;
}

// TABS style
.nav-tabs {
  .nav-item {
    flex: 1;
  }
  .nav-link {
    // height: 59px;
    width: 100%;
    // width: 211px;
    vertical-align: middle;
    font-family: $base-font1;
    font-size: 1.25rem;
    line-height: 2.1;
    text-align: center;
    @include onMobileScreen {
      height: 45px;
      width: 86px;
      font-size: 0.875rem;
    }
    @include onOnlyThisWidth(1024px) {
      height: 50px;
      width: 120px;
      font-size: 0.9375rem;
    }
  }
  .nav-link {
    border-radius: 16px 16px 0px 0px;
    &.active {
      background-color: #3674e8;
      color: $white-color;
    }
  }
  .dashboard {
    .nav-link.active {
      background-color: transparent !important;
    }
  }
}

thead {
  background-color: $color4;
  th {
    background: #e8f1fe;
    font-family: $base-font1;
    font-size: 1rem;
    font-weight: 500;
    line-height: 2;
    text-align: left;
    color: #717171;
  }
}

.filter-groups {
  img {
    width: 22px;
  }
  button {
    border-radius: 5px !important;
    border: solid 1px $color2;
    background: $white-color;
  }
  span {
    font-family: $base-font1;
    font-size: 1.125rem;
    line-height: 1.33;
    color: $color1;
    display: inline-block;
    width: 93px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    margin-bottom: -6px;
  }
  .dropdown-menu {
    width: 163px;
  }
}

.next-prev-icon {
  border-radius: 6px;
  border: solid 1px $color2;
  height: 40px;
  width: 104px;
  .input-group-text {
    background: transparent;
    border: 0px;
    margin: auto;
  }
  .input-group-prepend {
    width: 51px;
  }
  .border-right-middle {
    border-right: solid 1px $color2;
  }
}

.form-control {
  background: $body-bg;
  height: 66px;
  border-radius: 12px;
  font-family: $base-font1;
  font-size: 1.125rem;
  line-height: 2;
  color: $color1;
  @include onSmallScreen {
    font-size: 1rem;
    height: 50px;
  }
}
select.form-control:not([size]):not([multiple]) {
  height: 50px;
}

.form-group {
  margin-bottom: 50px;
  & > label {
    @include setHeading;
  }
}

.gutter-33 {
  margin-left: -33px;
  margin-right: -33px;
  div[class*="col"] {
    padding-left: 33px;
    padding-right: 33px;
  }
}
// .therapist-service-table {
//     table-layout: fixed ;
//     width: 100% ;
//     .thead{
//         font-size: 0.875rem;
//     }
//   }
.thead {
  font-size: 0.875rem;
}

// for stepper UI
.stepper {
  position: relative;
  display: flex;
  text-align: center;
  justify-content: center;
  background-color: $body-bg;
  margin-top: 25px;
  // margin-left: 21px;
  @include onSmallScreen {
    margin-top: 25px;
  }
  .stepper-steps {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: left;
    font-size: 1.5rem;
    @include onMaxWidth(1800px) {
      font-size: 1.3rem;
    }
    @include onLargeScreen {
      font-size: 1.1rem;
      padding-top: 15px;
      padding-bottom: 15px;
    }
    @include onSmallScreen {
      font-size: 1rem;
    }
    @include onMobileScreen {
      font-size: 0.625rem;
    }
    @include onOnlyThisWidth(1024px) {
      font-size: 1.125rem;
    }
    .step-count {
      border-radius: 50%;
      width: 2.5em;
      height: 2.5em;
      background-color: $color1;
      display: inline-flex;
      margin-right: 5px;
      label {
        color: $white-color;
        // font-size: 1.1875rem;
        font-size: 1em;
        display: block;
        margin: auto;
      }
    }
    .label-text,
    .label-text-family {
      display: inline-flex;
      height: 100%;
      align-items: center;
      font-family: $base-font1;
      color: $color1;
    }
    // .label-text-family {
    //     @include onMobileScreen{
    //         font-size: 0.375rem;
    //     }
    //     @include onOnlyThisWidth(1024px){
    //         font-size: 0.75rem;
    //     }
    //     @include onOnlyThisWidth(1536px){
    //         font-size: 1rem;
    //     }
    // }
  }
  .active {
    background-color: $color2;
    border-radius: 0px 50px 50px 0px;
    .label-text-family {
      color: $white-color;
    }
    .step-count {
      background-color: $white-color;
      label {
        color: $color2;
      }
    }
    .label-text {
      color: $white-color;
    }
  }
  .complete {
    border-radius: 0px 0px 0px 0px;
    background-color: $color2;
    .label-text-family {
      color: $white-color;
    }
    .step-count {
      background-color: $white-color;
      label {
        color: $color2;
      }
    }
    .label-text {
      color: $white-color;
    }
  }
  .done {
    border-radius: 0px 0px 0px 0px;
  }
}

.plubtn-rounded {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: none;
  background-image: linear-gradient(to bottom, $btn-green, #10c177);
  @include onSmallScreen {
    width: 50px;
    height: 50px;
  }
}

.btn-rounded-black {
  background-color: $black-color;
  border-radius: 50%;
  width: 46px;
  height: 46px;
  border: none;
}

.working-time {
  // max-width: 287px;
  max-width: 240px;
  width: 100%;
  justify-content: space-between;
  .input-group {
    width: 130px;
    height: 38px;
    .form-control {
      height: 38px;
    }
  }
  .set-time-section {
    font-family: $base-font1;
    font-size: 1.125rem;
    line-height: 1.33;
    .btn {
      background-color: $white-color;
      border-radius: 0px;
      border: solid 1px $color2;
      color: $color1;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      &:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
      &:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
    .active {
      background-color: $color2;
      color: $bg-color1;
    }
  }
}

.add-session-form {
  .working-time {
    max-width: 260px;
  }
}
.custom-select1 {
  background: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.333' height='8.003' viewBox='0 0 12.333 8.003'%3E%3Cpath fill='%23414141' d='M8.167 10L2 3.837 3.837 2l4.33 4.33L12.5 2l1.837 1.837z' transform='translate(-2 -2)'/%3E%3C/svg%3E")
    no-repeat;
  background-position: right center;
  background-size: 16px 10px;
  background-position: 94%;
}
// Select box
.input-group {
  .custom-select {
    border-radius: 12px;
    border: solid 2px $light-blue;
    background-color: $body-bg;
    height: 65px;
    font-family: $base-font1;
    font-size: 1.125rem;
    line-height: 1.33;
    text-align: left;
    color: $color5;
    background: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.333' height='8.003' viewBox='0 0 12.333 8.003'%3E%3Cpath fill='%23414141' d='M8.167 10L2 3.837 3.837 2l4.33 4.33L12.5 2l1.837 1.837z' transform='translate(-2 -2)'/%3E%3C/svg%3E")
      no-repeat;
    // background-size: 57px 10px;
    background-position: right center;
    background-size: 16px 10px;
    background-position: 94%;
    // @include setSelect;
    @include onSmallScreen {
      font-size: 1rem;
      height: 50px;
    }
  }
}

.bg-common-gray {
  background-color: $body-bg;
}

.working-days {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.cursor-pointer {
  cursor: pointer;
}

.action-devider {
  width: 0px;
  border: solid 1px $color2;
  height: 28.6px;
  opacity: 0.4;
}

// for floting textfield
.floting-form {
  .form-group {
    position: relative;
    margin-bottom: 1.5rem;
  }
  .form-control {
    padding-top: 20px;
  }
  .form-control-placeholder {
    position: absolute;
    top: 0;
    padding: 14px 0 0 13px;
    transition: all 200ms;
    opacity: 0.5;
    left: 0px;
  }
  .form-control:focus + .form-control-placeholder,
  .form-control:valid + .form-control-placeholder {
    font-size: 75%;
    transform: translate3d(0, -30%, 0);
    opacity: 1;
  }
  .form-control:-webkit-autofill + .form-control-placeholder {
    font-size: 75%;
    transform: translate3d(0, -30%, 0);
    opacity: 1;
  }
}

.dob-label {
  font-family: $base-font1;
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.3;
  color: $color1;
  @include onSmallScreen {
    font-size: 1rem;
  }
  label {
    width: 100%;
  }
}

.user-info-table {
  .info-name {
    font-family: $base-font1;
    font-size: 1.375rem;
    font-weight: bold;
    line-height: 1.91;
    color: $color1;
    @include onSmallScreen {
      font-size: 1.125rem;
    }
  }
  .info-dob {
    font-family: $base-font1;
    font-size: 1.375rem;
    line-height: 1.91;
    color: $color1;
    @include onSmallScreen {
      font-size: 1.125rem;
    }
  }
  small {
    font-family: $base-font1;
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 2;
    color: $color5;
  }
}

.required-span {
  color: #ff0000;
}

.invalid-feedback-custom {
  color: #ff0000;
}

.form-control + .is-invalid {
  border-color: #dc3545;
}

.custom-select.is-invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545;
  border-width: unset;
}

//therapist video theraphy
.custom-btn-red {
  border-radius: 6px;
  border: none;
  font-family: $base-font1;
  font-size: 1.125rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: $white-color;
  width: 145px;
  height: 43px;
  padding: 10px 23px 12px;
  background-color: #fc3434;
  :not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    background-color: #fc3434;
    border-color: none;
  }
}

.paperclip-btn {
  width: 39px;
  height: 38px;
  margin: 0 14px 0px 5px;
  padding: 10px 15.3px 10.5px 15px;
  background-color: $light-blue;
  border-radius: 50%;
}

.paperclip-btn-p {
  font-size: 0.875rem;
}

.audio-video-background {
  width: 57px;
  height: 56px;
  margin: 38px 10px 7px 377px;
  padding: 20.2px 17.3px 20.2px 16.4px;
  background-color: $light-blue;
  border-radius: 50%;
  text-align: center;
}

.parent {
  position: relative;
  width: 120%;
  height: 100%;
}

.over-img {
  position: absolute;
  top: 65%;
  left: 10%;
}

.avc__video-container--has-pinned .avc__video-wrapper .over-img-middle {
  top: 18% !important;
  z-index: 1 !important;
}

.avc__video-container--has-pinned .avc__video-wrapper .over-img-middle img {
  width: 90px !important;
  height: 90px !important;
}

.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width: auto;
  // padding: 5px;
  border-radius: 4px;
}

.popup-header {
  border-bottom: 1px solid #dfdfdf;
  margin-bottom: 12px;
  margin-top: 12px;
}

.popup-btn-container {
  border-top: 1px solid #dfdfdf;
  margin-bottom: 5px;
  padding-top: 5px;
  margin-top: 12px;
}

.popup-btn-container .btn {
  margin: 5px;
}

.popup-arrow {
  color: rgb(255, 255, 255);
}
[role="tooltip"].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup="tooltip"].popup-overlay {
  background: transparent;
}

.over-img-middle {
  position: absolute;
  left: 50%;
  z-index: 9;
  top: 37%;
  -ms-transform: translateY(-50%);
  -ms-transform: translateX(-50%);
  transform: translateY(-50%);
  transform: translateX(-50%);
}
.over-img-middle img {
  width: 140px;
  height: 140px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
}

.over-img-middle .text {
  color: white;
  font-size: 45px;
  background-color: #bf35d2;
  font-weight: bold;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  padding: 55px 40px 55px 40px;
  text-align: center;
  vertical-align: middle;
  line-height: 30px;
  width: 140px;
  text-transform: uppercase;
}

.avc__video-container--has-pinned .avc__video-wrapper .over-img-middle .text {
  width: 80px !important;
  // height: 10px !important;
  font-size: 30px !important;
  line-height: 0px !important;
  padding: 40px 20px 40px 20px !important;
}

//end
.time-box {
  width: 150px;
  height: 50px;
}

.add-session-form .input-group-prepend {
  width: 44px;
  background-color: $color2;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  .time-box {
    width: 150px;
    height: 50px;
  }
}
.team-heading {
  margin-right: 55%;
}

.add-session {
  h6 {
    color: $color1;
  }
  .selected-date {
    font-weight: bold;
    color: $color2;
    font-family: $base-font1;
    font-size: 1.25rem;
  }
}
.radio-disabled {
  background: lightgray;
}

.css-wmw4vi-ReactDropdownSelect {
  width: 560px !important;
  background: $body-bg;
  border-radius: 12px !important;
  font-family: $base-font1;
  font-size: 1.125rem;
  line-height: 2;
  color: $color1;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.css-1njzwg6-InputComponent {
  font-size: 1.125rem !important;
  @include onSmallScreen {
    font-size: 1rem !important;
  }
}

.form-control-time {
  .form-control {
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
    margin-left: 10px;
    text-align: center;
    border: solid 1px $color3;
    border-radius: 6px;
  }
}
.tableSelected {
  background-color: blue;
}

.rdtPicker {
  min-width: 109px !important;
  margin-left: 50px !important;
}

.input-group .custom-select:disabled {
  background-color: #e9ecef !important;
}

.input-btn-green {
  border-radius: 6px;
  background-image: linear-gradient(to bottom, $btn-green, #10c177 93%);
  border: none;
  font-family: $base-font1;
  font-size: 1rem;
  color: $bg-color1;
  width: 100px;
  height: 43px;
  // padding: 10px 23px 12px;
  background-color: $btn-green;
  margin-top: 10%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  .choosefile {
    margin: auto !important;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .change1 {
    margin: auto;
  }
  @include onSmallScreen {
    font-size: 0.875rem;
  }
  :not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    background-color: $btn-green;
    border-color: none;
  }
}

.custom-modal {
  .show {
    display: block;
    padding-right: 15px;
    display: block;
    background: #80808061;
  }
}

.session-dd {
  font-size: 1.25rem;
  color: $color1;
  margin-bottom: 0;
  @include onMediumScreen {
    font-size: 1.125rem;
  }
}

.session-detail-date {
  font-family: $base-font1;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.33;
  text-align: left;
  color: $color1;
  b {
    font-weight: 600;
  }
}

.table-th-merge-gap {
  margin-left: -1px;
}
.noModalbutton {
  border-radius: 6px;
  width: 150px;
  height: 43px;
  border: 1px solid $color2;
  font-family: $base-font1;
  font-size: 1.125rem;
  line-height: 1.31;
  text-align: center;
  color: $color2;
  background-color: azure;
}
.Yes-green-button {
  border-radius: 6px;
  background-image: linear-gradient(to bottom, $btn-green, #10c177 93%);
  border: none;
  font-family: $base-font1;
  font-size: 1.125rem;
  color: $bg-color1;
  width: 150px;
  height: 43px;
  //padding: 10px 23px 12px;
  background-color: $btn-green;
}
.cancelsessionbutton {
  border-radius: 7px;
  width: 200px;
  height: 50px;
  border: 1px solid $color2;
  font-family: $base-font1;
  font-size: 1rem;
  line-height: 1.31;
  text-align: center;
  color: $color2;
  background-color: azure;
}

.submitreqbutton {
  border-radius: 7px;
  width: 200px;
  height: 50px;
  font-family: $base-font1;
  font-size: 1rem;
  line-height: 1.31;
  text-align: center;
}
// .modal{
//     overflow: scroll;
// }
.modal-content {
  border-radius: 16px;
  border: none;
  *width: 140%;
  .modal-header {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background-color: $bg-color2;
    border: none;
  }
}
//  .modal-content .close {
//     font-size: 1.875rem;
//     color:white;
//     background-color: #91b7ff;
//     border-radius: 50%;
//     height: 30px;
//     width: 65px;
//     margin-top: -48px;
//     margin-right: -40px;
// }

.modal-content1 {
  border-radius: 16px;
  border: none;
  width: 150%;
  .modal-header1 {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background-color: $bg-color2;
    border: none;
    height: 60px;
    width: 150%;
  }
  .modal-body .editsession {
    margin-right: 10%;
    width: 150%;
    background-color: $white-color;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}
.modal-content .close {
  -webkit-tap-highlight-color: transparent;
  // font-weight: 0;
  font-size: 1.875rem;
  color: white;
  background-color: #91b7ff;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  // margin-top: -40px;
  // margin-right: -40px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -40px;
  margin-right: -17px;
  position: absolute;
  right: 0;
}
.cross {
  margin-top: 15px;
}
.addlang {
  margin-left: 36%;
}
.session-cancel-head {
  margin-left: 28%;
}
.session-edit-head {
  margin-left: 36%;
  margin-top: 2%;
}
.addservice {
  margin-left: 40%;
}
.deleteicon {
  height: 30px;
}

.headersession {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: $bg-color2;
  border: none;
  height: 60px;
  width: 140%;
  margin-left: -18%;
}

.editsession {
  margin-left: -18%;
  height: 150%;
  width: 140%;
  background-color: $white-color;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

// For loader css
.loader {
  -webkit-animation: spin 0.7s linear infinite;
  animation: spin 0.7s linear infinite;
  border: 6px solid #ddd;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  position: absolute;
  top: calc(50% - 25px) !important;
  left: calc(50% - 25px) !important;
  border-top: 6px solid $color2 !important;
  &-fixed-contaienr {
    background-color: rgba($white-color, 0.95);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9999999;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-small {
  -webkit-animation: spin 0.7s linear infinite;
  animation: spin 0.7s linear infinite;
  border: 4px solid #ddd;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  // position: absolute;
  margin: auto;
  margin-top: 10px;
  top: 50% !important;
  left: 50% !important;
  border-top: 4px solid $color2 !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.filter-groups1 {
  img {
    width: 30px;
  }
  button {
    border-radius: 5px !important;
    background: #dce8fc;
  }
  span {
    font-family: $base-font1;
    font-size: 1.125rem;
    line-height: 1.33;
    color: $color1;
    display: inline-block;
    width: 60px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    margin-bottom: -6px;
  }
  .dropdown-menu {
    width: 60px;
  }
}
.back {
  background-color: #dce8fc;
  border-radius: 8px;
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 2px;
}
.time {
  color: $color2;
}

@import url("//netdna.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css");
.accordion-toggle:after {
  font-family: "FontAwesome";
  content: "\f078";
  float: right;
}
.accordion-opened .accordion-toggle:after {
  content: "\f054";
}

.accordion-started.accordion-bral {
  min-height: 0;
  min-width: 220px;
  width: 100%;
  background-color: #fff;
  margin: 0px !important;
  color: #484848;
}
.inner-back {
  background-color: $body-bg;
  padding: 40px 40px 40px 40px;
}

.accordion-started.accordion-bral .ac-label {
  font-family: Arial, sans-serif;
  padding: 5px 20px;
  position: relative;
  display: block;
  height: auto;
  cursor: pointer;
  color: #393941;
  border: solid 1px #c4cbd6;
  border-radius: 4px;
  line-height: 33px;
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.search {
  background: $white-color;
  height: 50px;
  font-family: $base-font1;
  font-size: 1.125rem;
  line-height: 2;
  color: $color1;
  @include onSmallScreen {
    font-size: 1rem;
    height: 50px;
    // height:50px;
  }
}
.addyourque {
  height: 200px;
  background-color: $color4;
}
.Could-Not-Find-Your-Question {
  margin-top: 10%;
  margin-left: 8%;
  font-family: $base-font1;
  font-size: 1.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.87;
  letter-spacing: normal;
  text-align: left;
  color: #393941;
}

.email-send-success {
  color: green;
  font-family: $base-font1;
  font-size: 1.75rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.modaldate {
  font-weight: bold;
  color: $color2;
}
.cancelreqtextarea {
  width: 450px;
  // height: 120px;
  // border-radius: 14px;
  // border: solid 2px #cecece;
  // background-color: $white-color;
}
.cancelled-icon {
  height: 20px;
  width: 20px;
  margin-top: -2%;
}
.settings-change-button {
  margin-top: 18%;
}

.modal-dialog1 {
  margin-left: 26%;
}
.modal-dialog-addservice {
  margin-left: 33%;
  margin-top: 2%;
  @include onMobileScreen {
    margin-left: 18%;
    margin-top: -10%;
  }
  @include onOnlyThisWidth(1024px) {
    margin-left: 26%;
    margin-top: 2%;
  }
}
.faq {
  margin-left: 53%;
}

#main {
  margin: 50px 0;
}
#main #faq .card {
  margin-bottom: 10px;
  border: 1px solid #c4cbd6;
}
#main #faq .card .card-header {
  border: 0;
  border-radius: 2px;
  padding: 0;
}
#main #faq .card .card-header .btn-header-link {
  border: none;
  display: block;
  text-align: left;
  background: $body-bg;
  color: #222;
  padding: 20px;
  font-weight: bold;
  font-size: 1.25rem;
}
#main #faq .card .card-header .btn-header-link:after {
  //content: "hgfvhgc";
  //  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  float: right;
}
#main #faq .card .card-header .btn-header-link.collapsed {
  background-image: url(https://cdn0.iconfinder.com/data/icons/arrows-android-l-lollipop-icon-pack/24/expand2-256.png);
  background: #fff;
  color: black;
  border: none;
  font-weight: bold;
  font-size: 1.25rem;
}
#main #faq .card .card-header .btn-header-link.collapsed:after {
  // content: "fghdfn";
  border: none;
}
#main #faq .card .collapsing {
  background: $body-bg;
  line-height: 30px;
  border: none;
}
#main #faq .card .collapse {
  border: 0;
  border: none;
}
#main #faq .card .collapse.show {
  background: $body-bg;
  line-height: 30px;
  color: #222;
  border: none;
}
.status {
  margin-left: 450%;
  @include onMobileScreen {
    margin-left: 175%;
  }
  @include onOnlyThisWidth(1024px) {
    margin-left: 310%;
  }
}
.resources-image {
  .c-attachments__file-inner {
    border: 2px solid $color1;
    // width: 91px;
    // height: 91px;
    // object-fit: contain;
    // border-radius: 11px;
    //background-color: #dc35b8;
    // margin-left: 12px;
    cursor: pointer;
    // display: inline-flex;
    // align-items: center;
    // justify-content: center;
    background: $color1;
  }
  &:hover {
    opacity: 0.5;
    cursor: zoom-in;
  }
}
.resources-modal-image {
  width: 100%;
  object-fit: contain;
  //  background-color: #dc35b8;
}

.therapy-session-search {
  width: 80%;
}
.therapy-session-week {
  margin-top: -25px;
  justify-content: flex-end;
}

.text-info-tips {
  font-family: $base-font1;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.19;
  color: $color3;
  @include onSmallScreen {
    font-size: 0.875rem;
  }
}

.text-info-div {
  vertical-align: middle;
  display: flex;
}

.attachment-icon {
  background-color: $light-blue;
  height: 47px;
  width: 47px;
  border: none;
  border-radius: 50%;
}
.attahment-section {
  .image-files {
    display: flex;
    border-radius: 10px;
    width: 76px;
    height: 75px;
  }
}

.attachment-images-section-view .column {
  button {
    position: absolute;
    z-index: 1;
    right: 0;
    border: none;
    background: transparent;
    top: -12px;
  }
  img {
    position: relative;
    width: 100%;
  }
}

.file-input-wrapper {
  height: 47px;
  position: relative;
  width: 400px;
  cursor: pointer;
  overflow: hidden;
}

.file-input-wrapper > input[type="file"] {
  font-size: 2.5rem;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
}

.icd-h-desc {
  height: 120px;
  overflow: auto;
}
.react-date-picker__wrapper {
  border: none !important;
}

.assigned-therapy {
  border-radius: 6px;
  background-color: $light-blue;
  td {
    font-family: $base-font1;
    font-size: 1.25rem;
    line-height: 1.2;
    color: $color1;
    vertical-align: top;
    @include onSmallScreen {
      font-size: 1rem;
    }
    span {
      font-weight: bold;
    }
    .images {
      .img {
        width: 75px;
      }
      position: relative;
      width: 92px;
      button {
        position: absolute;
        z-index: 1;
        right: 0;
        border: none;
        background: transparent;
        top: -12px;
      }
      img {
        position: relative;
        width: 100%;
      }
    }
  }
}

.border-sestion-full {
  border-bottom: 1px solid #dee2e6;
}

.filter-next-prev {
  border-radius: 6px;
  border: solid 1px $color2;
  .next {
    border-right: solid 1px $color2;
  }
}

.next-prev-session-day-list {
  width: 80px;
  margin: auto;
  margin-top: 10px;
}

a.custom-nav-link-padding {
  padding: 0.5rem 1rem !important;
  display: block !important;
  cursor: default !important;
}

.preview {
  word-break: break-word;
  white-space: pre-wrap;
}

#exampleFormControlSelect1 {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMy4xNTgiIGhlaWdodD0iNy41IiB2aWV3Qm94PSIwIDAgMTMuMTU4IDcuNSI+CiAgICA8cGF0aCBmaWxsPSIjMzg3YWY2IiBkPSJNNi41OCAxMDQuNjQxYS45MTkuOTE5IDAgMCAxLS42NTEtLjI3TC4yNzEgOTguNzEzYS45MjEuOTIxIDAgMCAxIDEuMy0xLjNsNS4wMDYgNS4wMDYgNS4wMDYtNS4wMDZhLjkyMS45MjEgMCAwIDEgMS4zIDEuM2wtNS42NTggNS42NThhLjkxOS45MTkgMCAwIDEtLjY0NS4yN3oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0uMDAxIC05Ny4xNDEpIi8+Cjwvc3ZnPgo=);
  //background-image: url(data:image/svg+xml;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAXCAYAAADk3wSdAAAABHNCSVQICAgIfAhkiAAAAUhJREFUOE/tlbFRAzEQRf83AYR0YEkVmAowAXOXmRLcAe4AuwOoAOjA4XlIXMK5gZPowCHRfUbns8GgGYYzzthIs9I+7e7XaImEWZvNSZZVVUxT+85lE4kj74ur1D7T0HwZ/d4XwzQ0j5fdVVWRjKcxw/Ne7/RR4oCEiRBpg2Iy5Ps1ktYklnVdz0J4KdmWOpLwTCK00HELfUplKmFI4hLArD1vSN1I8N4vLuhcLkAPVbWYbAHW/r5857J7gLexJS0Us8+iGJPHTEMIRdPbr2ZMHnttQih2lTj30eckNAX6yXd8qLWZAEaBGpEOMAPIeL9gVD9CX/8I2m+gG/X3heqS7fF7+p/pv1BHeaf7X9/Bj9/arATQlzAFenHdweo4NaYAV3EE0ZjrAXkyJ9HvQNuFSFpJ9bgZJ1tvnFXA2aAb+K0MYbnexr4DU6nzGJxvBWkAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-position-x: 92%;
  background-position-y: 19px;
  margin-right: 0rem;
  padding-right: 2rem;
}
.arrow {
  float: right;
}

.rbc-month-view {
  display: block !important;
}
.family-details {
  margin-top: 50px;
  @include onMobileScreen {
    margin-top: 23px;
  }
}
.dash-count-blue {
  padding-left: 0px;
  @include onMobileScreen {
    padding-left: 15px;
  }
}
.pagination {
  border: none;
  .pagination:hover {
    background-color: $color2;
    color: white;
  }
}
.pagination-active,
li:hover {
  border-radius: 6px;
  background-color: $color2;
  color: white;
}

.nxt-pre {
  border: none;
  .nxt-pre:hover {
    background-color: white;
  }
}
.nxt-pre-active,
li:hover {
  border-radius: 6px;
  background-color: white;
}

.page-link:hover {
  background-color: white;
}
.for-dash {
  height: 30%;
  overflow: scroll;
}
.name-table {
  @include onMobileScreen {
    font-size: 0.625rem;
  }
  @include onOnlyThisWidth(1024px) {
    font-size: small;
  }
}

.eventimage {
  width: 100px;
}

.settingsinput {
  @include onMobileScreen {
    width: 240px;
  }
}
.session-tab-heading {
  @include onOnlyThisWidth(768px) {
    margin-right: 10px;
  }
}
.fam {
  @include onOnlyThisWidth(1024px) {
    margin-left: 5%;
  }
}
.padding-0 {
  padding-right: 0;
  padding-left: 0;
}
.t-back {
  width: auto;
  height: auto;
  padding: 9.4px 60px 11.8px;
  border-radius: 8px;
  background-color: $white-color;
  border: solid 1px $color2;
  font-family: $base-font1;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  color: $color6;
  @include onlyOnTabScreen {
    font-size: 1rem;
  }
}
.t-edit {
  width: auto;
  height: auto;
  padding: 9.4px 60px 11.8px;
  border-radius: 8px;
  background-image: linear-gradient(to bottom, $btn-yellow, #e5c01d);
  font-family: $base-font1;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  color: $color6;
  @include onlyOnTabScreen {
    font-size: 1rem;
  }
}
.t-cancel {
  width: auto;
  height: auto;
  padding: 9.4px 36.6px 10.6px 38.4px;
  border-radius: 8px;
  border: solid 1px #3674e8;
  font-family: $base-font1;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: center;
  color: #3674e8;
  background-color: azure;
  @include onlyOnTabScreen {
    font-size: 1rem;
    margin-left: 20%;
  }
  @include onOnlyThisWidth(1024px) {
    margin-left: 10%;
  }
}
.billing-info-search {
  margin: 0 16px 0 0;
  padding: 14px 13px;
  border-radius: 4px;
  border: solid 1px $color3;
  background-color: $white-color;
  height: 50px;
}
.billing-info-nxtpre {
  margin-left: 277%;
  @include onMobileScreen {
    margin-left: 100%;
  }
  @include onOnlyThisWidth(1024px) {
    margin-left: 187%;
  }
}
.dashboard-session-list {
  margin-left: 130%;
  @include onMobileScreen {
    margin-left: 2%;
  }
  @include onOnlyThisWidth(1024px) {
    margin-left: 90%;
  }
}
.interpreter-detail-btn {
  margin-left: 12%;
  @include onMobileScreen {
    margin-left: 0;
  }
  @include onOnlyThisWidth(1024px) {
    margin-left: 5%;
  }
}
.header-reqsession {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: $bg-color2;
  border: none;
  height: 60px;
  width: 150%;
  margin-left: -22%;
  @include onMobileScreen {
    margin-left: -23.5%;
  }
}
.body-reqsession {
  margin-left: -22%;
  width: 150%;
  background-color: $white-color;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  @include onMobileScreen {
    margin-left: -23.5%;
  }
}
.insurance-table {
  word-break: break-word;
}
.billing-blue-count {
  margin-top: 15%;
  @include onMinWidth(1200px) {
    margin-top: 20%;
  }
  @include onMobileScreen {
    margin-top: 30%;
  }
}
.donut-inner {
  margin-top: -36%;
  margin-left: 38%;
}
.border {
  border-radius: 6px;
  border: solid 3px rgb(17, 67, 216);
}
.add-button {
  border-radius: 10px;
  background-image: linear-gradient(to bottom, $btn-green, #10c177);
  width: 200;
  height: 66px;
  border: none;
  font-family: $base-font1;
  font-size: 1.0625rem;
  line-height: 1.31;
  text-align: center;
  color: $white-color;
  padding-left: 20px;
  padding-right: 20px;
  @include onSmallScreen {
    font-size: 1rem;
    width: 150px;
    height: 50px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.assigntherapist {
  margin-top: 35px;
  @include onSmallScreen {
    margin-top: 30px;
  }
}
.copyright-image {
  width: 53.8px;
  height: 25.7px;
}
.copyright-text {
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #191919;
}
.copyright-image-sidebar {
  width: 40px;
  height: 20px;
  @media screen and (min-width: 1024px) and (max-width: 1024px) {
    left: 10px;
  }
}
.copyright-text-sidebar {
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #191919;
  @media screen and (min-width: 1024px) and
  (max-width:1024px) {
    left: 50px;
  }
  @media screen and (max-width: 768px) {
    width: 60px;
    overflow-wrap: break-word;
  }
}
.description {
  word-break: break-word;
  white-space: pre-line;
}
.datepickercal {
  width: auto;
  height: auto;
  font-size: 14px;
  border: solid 1px #387af6;
  padding: 10px 8px 10px 8px;
  border-radius: 10px;
}
.billing-dropdown {
  border: solid 1px #387af6;
}
.padding-right-0 {
  padding-right: 0;
}
.description {
  word-break: break-word;
  white-space: pre-line;
}

.btn-theme-info {
  background-color: #f4f9ff;
  border-color: #aed0f7;
}
.btn-red {
  border-color: #d05a5a;
  fill: #d05a5a;
}